<template>
  <section>
    <v-sheet
      color="white"
      elevation="4"
      outlined
      rounded
      width="352px"
      class="pa-5 mb-12 rounded-lg"
    >
      <v-form id="activation" ref="activation" :model="form" :rules="rules" @submit.native.prevent="activationAccount">
        <v-text-field
          v-model="form.fullName"
          :label="$_strings.welcomePage.FULL_NAME"
          clearable
          dense
          class="mb-5"
          :rules="rules.fullName"
          @focus="clearFullName"
          @blur="blurRulesFullName"
          :hint="emailHint"
          persistent-hint
          :readonly="isReadonly"
        ></v-text-field>
        <v-text-field
          v-model="form.email"
          ref="email"
          :label="$_strings.welcomePage.EMAIL"
          clearable
          disabled
          dense
          class="mb-5"
          @focus="clearRulesEmail"
          @blur="blurRulesEmail"
          :hint="emailHint"
          persistent-hint
        ></v-text-field>
        <v-text-field
          v-model="form.phoneNo"
          ref="phoneNumber"
          clearable
          dense
          disabled
          :label="$_strings.welcomePage.TELP"
          @focus="clearRulesPhoneNumber"
          @blur="blurRulesPhoneNumber"
          class="body-2 mb-5"
          persistent-hint
        ></v-text-field>
        <v-text-field
          ref="password"
          v-model="form.password"
          class="mb-5"
          dense
          :label="$_strings.welcomePage.PASSWORD"
          :type="passwordShow ? 'text' : 'password'"
          :rules="rules.password"
          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="passwordShow = !passwordShow"
        ></v-text-field>
        <v-text-field
          ref="password"
          v-model="form.repeatPassword"
          class="mb-5"
          dense
          :label="$_strings.welcomePage.REPEAT_PASSWORD"
          :type="repeatPasswordShow ? 'text' : 'password'"
          :rules="rules.repeatPassword"
          :append-icon="repeatPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="repeatPasswordShow = !repeatPasswordShow"
        ></v-text-field>
        <div class="d-flex align-center">
          <v-checkbox v-model="isAgree"></v-checkbox>
          <div class="body-2">
            {{$_strings.welcomePage.ISAGREE}}
            <a
              @click="dialogTermsConditions = true"
            >
              {{$_strings.welcomePage.TERMS}}
            </a>
          </div>
        </div>
        <vue-recaptcha
          ref="recaptcha"
          @verify="onVerify"
          @expired="onExpire"
          @error="onError"
          class="mb-2"
          :sitekey="keys().siteKey">
        </vue-recaptcha>
        <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100" type="submit" :disabled="!verify">
          {{$_strings.welcomePage.CONTINUE_REGISTRATION}}
        </v-btn>
      </v-form>
    </v-sheet>
    <welcome-footer/>
    <dialog-terms-conditions :visible="dialogTermsConditions" @setDialog="setDialog"/>
  </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import keys from '../../../keys';
import WelcomeFooter from './WelcomeFooter.vue';

export default {
  name: 'email-verification',
  components: {
    VueRecaptcha,
    WelcomeFooter,
  },
  mounted() {
    const { token } = this.$route.query;
    if (token) this.decodeAccount(token);
  },
  data() {
    return {
      yearNow: new Date().getFullYear(),
      dialogTermsConditions: false,
      form: {
        fullName: '',
        email: '',
        phoneNo: '',
        password: '',
        repeatPassword: '',
      },
      passwordShow: false,
      repeatPasswordShow: false,
      emailHint: '',
      rules: {
        fullName: [
          (v) => {
            if (v) {
              return !!v;
            }
            return this.$_strings.messages.validation.REQUIRED('Nama Lengkap sesuai KTP/KITAS');
          },
        ],
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
        phoneNumber: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('No. Telepon'),
          (value) => this.isPhoneNumberValid(value) || this.$_strings.messages.validation.PHONE_NUMBER,
        ],
        password: [
          (v) => {
            if (v) {
              const upperCaseLetters = /[A-Z]/g;
              const numbers = /[0-9]/g;
              const symbol = /[$-/:-?{-~!"^_`\\[\]\s@]/;
              if ((!symbol.test(v) || v.includes(' ') || !v.match(upperCaseLetters)) || (!v.match(numbers)) || (v.length < 6)) {
                return 'Password wajib alphanumeric, kapital, simbol, tidak memiliki spasi dan minimal 6 karakter';
              }
              return !!v;
            }
            return this.$_strings.messages.validation.REQUIRED('Password');
          },
        ],
        repeatPassword: [
          (v) => {
            if (v !== this.form.password) {
              return this.$_strings.messages.validation.PASSWORD_NOT_MATCH;
            }
            if (!v) {
              return this.$_strings.messages.validation.REQUIRED('Ulang Kata Sandi');
            }
            return true;
          },
        ],
      },
      // captcha
      isAgree: false,
      verified: false,
      expired: false,
      tokenCaptcha: null,
      isReadonly: true,
    };
  },
  computed: {
    verify() {
      return this.verified && this.isAgree;
    },
  },
  methods: {
    keys,
    decodeAccount(token) {
      this.$root.$loading.show();
      this.$_services.account.decodeToken(token)
        .then((result) => {
          this.form.email = result.data.email;
          this.form.phoneNo = result.data.phoneNo;
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    activationAccount() {
      const valid = this.$refs.activation.validate();
      if (valid && this.tokenCaptcha) {
        this.$root.$loading.show();
        this.$_services.account.activateAccount(this.form)
          .then((result) => {
            this.$root.$loading.hide();
            this.login();
          })
          .catch((err) => {
            this.$root.$loading.hide();
          });
      }
    },
    clearRulesEmail() {
      this.emailHint = this.$_strings.welcomePage.EMAIL_HINT;
      this.rules.email = [];
    },
    blurRulesEmail() {
      this.emailHint = '';
      const rulesEmail = [
        (v) => {
          if (v) {
            return true;
          }
          return this.$_strings.messages.validation.REQUIRED('Email');
        },
        (value) => {
          if (this.isEmailValid(value)) {
            return true;
          }
          return this.$_strings.messages.validation.EMAIL;
        },
      ];
      this.rules.email = rulesEmail;
    },
    clearRulesPhoneNumber() {
      this.rules.phoneNumber = [];
    },
    blurRulesPhoneNumber() {
      const rulesPhoneNumber = [
        (v) => {
          if (v) {
            return !!v;
          }
          return this.$_strings.messages.validation.REQUIRED('No. Telepon');
        },
        (value) => {
          const pattern = /^(\+62|62|0)8[1-9][0-9]{6,11}$/;
          if (pattern.test(value)) {
            return pattern.test(value);
          }
          return this.$_strings.messages.validation.PHONE_NUMBER;
        },
      ];
      this.rules.phoneNumber = rulesPhoneNumber;
    },
    clearFullName() {
      this.isReadonly = false;
      this.rules.fullName = [];
    },
    blurRulesFullName() {
      const rulesFullName = [
        (v) => {
          if (v) {
            return !!v;
          }
          return this.$_strings.messages.validation.REQUIRED('Nama Lengkap sesuai KTP/KITAS');
        },
      ];
      this.rules.fullName = rulesFullName;
    },
    onVerify(token, ekey) {
      this.verified = true;
      this.tokenCaptcha = token;
      this.eKey = ekey;
    },
    onExpire() {
      this.verified = false;
      this.tokenCaptcha = null;
      this.eKey = null;
      this.expired = true;
    },
    onError(err) {
      this.tokenCaptcha = null;
      this.eKey = null;
      this.error = err;
    },
    navigateToPageMain() {
      if (this.$store.getters['auth/isLoggedIn']) {
        const menu = JSON.parse(this.$store.getters['auth/menu']);
        if (menu[0].child.length > 0) {
          this.$router.replace(menu[0].child[0].url);
          return;
        }
        this.$router.replace(menu[0].url);
      }
    },
    async login() {
      const { email, password } = this.form;
      const form = { email, password };
      try {
        this.$root.$loading.show();
        await this.$store.dispatch('auth/login', form);
      } finally {
        this.navigateToPageMain();
        this.$root.$loading.hide();
      }
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
  },
};
</script>

<style lang="scss" scoped>
section .v-footer {
  background: transparent !important;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
